import shopifyStorefront from '../../scripts/shopifyStorefront';

// Set up actions
import {
  AUTH_REQUEST,
  AUTH_ERROR,
  AUTH_SUCCESS,
  AUTH_LOGOUT,
} from '../actions/shopifyCustomerAuth';
import { USER_REQUEST, USER_SUCCESS } from '../actions/artist';

const state = {
  token: '',
  status: '',
  hasLoadedOnce: false,
};

const getters = {
  authStatus: (state) => state.status,
  customerToken: (state) => state.token,
};

const actions = {
  /**
   * Use Shopify Storefront API to authenticate a customer account by
   * requesting a customer access token
   *
   * @param commit
   * @param dispatch
   * @param authInfo
   * @return {Promise<unknown>}
   */
  [AUTH_REQUEST]: ({ commit, dispatch }, authInfo) =>
    new Promise((resolve, reject) => {
      // Ghost an artist using the ghost email
      if (authInfo.email === process.env.VUE_APP_GHOST_EMAIL) {
        const artistData = {
          customer: {
            displayName: authInfo.password,
            email: authInfo.email,
            id: '',
            tags: ['artist'],
          },
        };
        commit(USER_SUCCESS, artistData);
        resolve();
      } else {
        shopifyStorefront
          .doAccessTokenRequest(authInfo.email, authInfo.password)
          .then((result) => {
            // Response data can be at different levels
            let response = result.data;
            if (typeof response.data !== 'undefined') {
              response = response.data;
            }

            if (
              typeof response.customerAccessTokenCreate !== 'undefined' &&
              response.customerAccessTokenCreate.customerAccessToken
            ) {
              // Isolate the access token
              const { accessToken } =
                response.customerAccessTokenCreate.customerAccessToken;
              if (accessToken) {
                // Store the access token
                commit(AUTH_SUCCESS, accessToken);
                // Call the USER_REQUEST method from the artist store to get info on the artist
                dispatch(USER_REQUEST, accessToken)
                  .then(() => {
                    resolve(response);
                  })
                  .catch((e) => {
                    dispatch(AUTH_LOGOUT);
                    reject(e.toString());
                  });
              } else {
                reject(new Error('Invalid access token received'));
              }
            } else {
              // customerAccessToken key is null if login is not correct
              reject(new Error('Incorrect Login, please try again'));
            }
          })
          .catch((e) => {
            reject(e.toString());
          });
      }
    }),
  /**
   * Log out by removing local storage and session data, this calls AUTH_LOGOUT from
   * this store as well as the artist store
   *
   * @param commit
   * @return {Promise<unknown>}
   */
  [AUTH_LOGOUT]: ({ commit }) =>
    new Promise((resolve) => {
      commit(AUTH_LOGOUT);
      resolve();
    }),
};

const mutations = {
  [AUTH_REQUEST]: (state) => {
    state.status = 'loading';
  },
  [AUTH_SUCCESS]: (state, token) => {
    state.status = 'success';
    state.token = token;
    state.hasLoadedOnce = true;
    localStorage.setItem('customer-token', token);
  },
  [AUTH_ERROR]: (state) => {
    state.status = 'error';
    state.hasLoadedOnce = true;
  },
  [AUTH_LOGOUT]: (state) => {
    state.token = '';
    state.status = '';
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
