import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';

Vue.use(VueRouter);

/**
 * Controls access to pages that require authentication
 *
 * @param to
 * @param from
 * @param next
 */
const ifAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    next();
    return;
  }
  next('/');
};

/**
 * Allows access to users who are not authenticated, redirects to
 * dashboard if user is authenticated
 *
 * @param to
 * @param from
 * @param next
 */
const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next();
    return;
  }
  next('/record-kit');
};

// Define the routes to be used by the router
const routes = [
  {
    path: '/',
    name: 'login',
    component: () =>
      import(/* webpackChunkName: "login" */ '@/pages/Login.vue'),
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: '/scanner',
    name: 'scanner',
    component: () =>
      import(
        /* webpackChunkName: "scanner" */ /* webpackPrefetch: true */ '@/pages/Scanner.vue'
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/artist-log',
    name: 'artist-log',
    component: () =>
      import(
        /* webpackChunkName: "scanner" */ /* webpackPrefetch: true */ '@/pages/ViewLog.vue'
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/record-kit',
    name: 'record-kit',
    component: () =>
      import(
        /* webpackChunkName: "scanner" */ /* webpackPrefetch: true */ '@/pages/RecordKit.vue'
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/add-order',
    name: 'add-order',
    component: () =>
      import(
        /* webpackChunkName: "scanner" */ /* webpackPrefetch: true */ '@/pages/AddOrder.vue'
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '*',
    name: 'not-found',
    component: () =>
      import(
        /* webpackChunkName: "notfound" */ /* webpackPrefetch: true */ '@/pages/NotFound.vue'
      ),
  },
];

const router = new VueRouter({
  mode: 'hash',
  routes,
});

export default router;
