import axios from 'axios';

/**
 * Interface with Lambda functions
 */
class Lambda {
  /**
   * Constructor
   *
   * @property {string} baseURL
   * @property {string} apiURL
   */
  constructor() {
    this.baseURL = '';
    this.apiURL = process.env.VUE_APP_SPIRIT_PIECES_API;
  }

  /**
   * Send request to Lambda Function to process the QR Code
   *
   * @param {string} orderName
   * @param {string} artistName
   * @return {Promise<AxiosResponse<any>>}
   */
  validateRecordKitInfo(orderName, artistName) {
    return axios.post(`${this.baseURL}/.netlify/functions/shipstation`, {
      action: 'getOrder',
      orderName,
      artistName,
    });
  }

  /**
   * Get a list of all of the orders for the logged in artist
   *
   * @param {string} artistName
   * @return {Promise<AxiosResponse<any>>}
   */
  getArtistLog(artistName) {
    return axios.post(`${this.baseURL}/.netlify/functions/database`, {
      action: 'viewLog',
      artistName,
    });
  }

  /**
   * Send the email that the Artist has received the Vial
   *
   * @param {object} orderData
   * @return {Promise<AxiosResponse<any>>}
   */
  sendArtistReceivedVial(orderData) {
    console.log('sendArtistReceivedVial:init', orderData);
    return axios.post(`${this.baseURL}/.netlify/functions/email-client`, {
      emailType: 'receipt',
      orderData,
    });
  }

  sendArtistMessageToArtist(orderData) {
    console.log('sendArtistMessageToArtist:init', orderData);
    return axios.post(`${this.baseURL}/.netlify/functions/email-client`, {
      emailType: 'artist-message',
      orderData,
    });
  }

  /**
   * Add a Workflow Item to the tracking database
   *
   * @param {string} artistName
   * @param {object} workflowData
   * @return {Promise<AxiosResponse<any>>}
   */
  addWorkflowItem(artistName, workflowData) {
    console.log('addWorkflowItem:init');
    if (artistName && workflowData) {
      return axios.post(`${this.baseURL}/.netlify/functions/database`, {
        action: 'addWorkFlowItem',
        artistName,
        workflowData,
      });
    }
    return Promise.reject(new Error('No Data'));
  }

  /**
   * Updates an existing Workflow Item
   *
   * @param artistName
   * @param workflowData
   * @return {Promise<never>|Promise<AxiosResponse<any>>}
   */
  updateWorkflowItem(artistName, workflowData) {
    console.log('updateWorkflowItem:init');
    if (artistName && workflowData) {
      return axios.post(`${this.baseURL}/.netlify/functions/database`, {
        action: 'updateWorkflowItem',
        artistName,
        workflowData,
      });
    }
    console.log('updateWorkflowItem:rejecting');
    return Promise.reject(new Error('No Data'));
  }

  /**
   * Update the record to show that the email has been sent
   *
   * @param {string} airtableRecordID
   * @return {Promise<AxiosResponse<any>>}
   */
  updateEmailSuccess(airtableRecordID) {
    console.log('updateEmailSuccess:init', airtableRecordID);
    return axios.post(`${this.baseURL}/.netlify/functions/database`, {
      action: 'updateEmailSuccess',
      airtableRecordID,
    });
  }

  /**
   * Retrieve the order from AirTable if it exists
   *
   * @param {string} orderName
   * @param {string} artistName
   */
  getOrderFromAirTableByShopifyOrderNameAndArtist(orderName, artistName) {
    console.log(
      'getOrderFromAirTableByShopifyOrderNameAndArtist:init',
      orderName
    );
    try {
      return axios.post(`${this.baseURL}/.netlify/functions/database`, {
        action: 'getOrderByOrderNameAndArtist',
        orderName,
        artistName,
      });
    } catch (err) {
      console.error(
        'getOrderFromAirTableByShopifyOrderNameAndArtist:error',
        err.toString()
      );
      return false;
    }
  }

  /**
   * Get Customer Email from Order Name
   *
   * @param orderName
   * @returns {boolean|Promise<AxiosResponse<any>>}
   */
  getCustomerEmail(orderName) {
    console.log('getCustomerEmail', orderName);
    try {
      return axios.get(`${this.apiURL}/order/getCustomerEmail/${orderName}`);
    } catch (err) {
      console.error('getCustomerEmail', err.toString());
      return false;
    }
  }

  /**
   * Get the Customer Name(s) and Artist Name(s) for a given order
   *
   * @param orderName
   * @returns {boolean|Promise<AxiosResponse<any>>}
   */
  getCustomerAndArtistNameForOrder(orderName) {
    console.log('getCustomerAndArtistNameForOrder', orderName);
    try {
      return axios.post(`${this.baseURL}/.netlify/functions/database`, {
        action: 'getCustomerAndArtistNameForOrder',
        orderName,
      });
    } catch (err) {
      console.error('getCustomerAndArtistNameForOrder:error', err.toString());
      return false;
    }
  }

  /**
   * Check the order name to see if there is a record in Shipstation
   *
   * @param orderName
   * @return {Promise<never>|Promise<AxiosResponse<any>>}
   */
  hasOrderBeenRecorded(orderName) {
    console.log('hasOrderBeenRecorded:init', orderName);
    try {
      return axios.get(`${this.apiURL}/order/orderPlaced/${orderName}`);
    } catch (err) {
      console.error('hasOrderBeenRecorded:error', err.toString());
      return Promise.reject();
    }
  }

  /**
   * Place an order manually given the Shopify Order Name
   *
   * @param orderName
   * @return {Promise<never>|Promise<AxiosResponse<any>>}
   */
  placeManualOrder(orderName) {
    console.log('placeManualOrder', orderName);
    try {
      return axios.post(`${this.apiURL}/order/manualOrder`, {
        orderName,
      });
    } catch (err) {
      console.error('getShopifyOrderDetails:error', err.toString());
      return Promise.reject();
    }
  }
}

const l = new Lambda();
export default l;
