<template>
  <v-footer fixed max-height="50" color="primary" v-if="loggedIn" class="pa-0">
    <v-col class="text-center" cols="12">
      <p v-html="artistName"></p>
    </v-col>
  </v-footer>
</template>

<script>
import store from '../store';

export default {
  name: 'appFooter',
  computed: {
    loggedIn: () => !!store.getters.isAuthenticated,
    artistName: () => {
      const { artistInfo } = store.getters;
      const artistNameRaw =
        typeof artistInfo.customer !== 'undefined'
          ? artistInfo.customer.displayName
          : '';
      if (typeof window.tracker !== 'undefined' && artistNameRaw !== '') {
        window.tracker.setUserID(artistNameRaw);
        window.tracker.setMetadata('email', artistInfo.customer.email);
      }
      return artistNameRaw;
    },
  },
};
</script>

<style scoped lang="scss">
.v-footer {
  p {
    color: #ddd;
    font-family: 'Kreon', sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 1em;
  }
}
</style>
