/**
 * Inline SVG Icons by Material Design.  Only import the icons being used and then
 * expose them via the icons object to the parent component
 */
import {
  mdiAlert,
  mdiAlertCircle,
  mdiArrowULeftTop,
  mdiChevronDown,
  mdiChevronRight,
  mdiChevronLeft,
  mdiClose,
  mdiContentSave,
  mdiDatabasePlus,
  mdiEmail,
  mdiEye,
  mdiInformation,
  mdiLogout,
  mdiLockQuestion,
  mdiMagnify,
  mdiMenu,
  mdiMenuDown,
  mdiQrcodeScan,
  mdiOpenInApp,
  mdiSkipNext,
  mdiSkipPrevious,
  mdiStopCircle,
} from '@mdi/js';

export default {
  data: () => ({
    icons: {
      mdiAlert,
      mdiAlertCircle,
      mdiArrowULeftTop,
      mdiChevronDown,
      mdiChevronRight,
      mdiChevronLeft,
      mdiClose,
      mdiContentSave,
      mdiDatabasePlus,
      mdiEmail,
      mdiEye,
      mdiInformation,
      mdiLogout,
      mdiLockQuestion,
      mdiMagnify,
      mdiMenu,
      mdiMenuDown,
      mdiQrcodeScan,
      mdiOpenInApp,
      mdiSkipNext,
      mdiSkipPrevious,
      mdiStopCircle,
    },
  }),
  methods: {
    /**
     * @return void
     */
    swapSVGIcons() {
      const replacementIcons = document.getElementById('replacement_icons');
      if (!replacementIcons) return;
      const replacements =
        replacementIcons.querySelectorAll('div[data-replaces]');
      if (!replacements) return;
      replacements.forEach((replacement) => {
        const replaces = replacement.getAttribute('data-replaces');
        if (replaces) {
          const iconElement = document.querySelector(replaces);
          if (iconElement) {
            iconElement.innerHTML = replacement.innerHTML;
            iconElement.style.width = 'auto';
            iconElement.style.height = 'auto';
          }
        }
      });
    },
  },
};
