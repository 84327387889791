<template>
  <v-app-bar app color="primary" dark>
    <v-navigation-drawer
      v-model="drawer"
      v-if="loggedIn"
      color="primary"
      absolute
      temporary
      right
      height="100vh"
    >
      <v-list-item>
        <v-list-item-icon>
          <spirit-pieces-logo />
        </v-list-item-icon>
        <v-list-item-content class="close__button">
          <div>
            <v-btn icon small text @click.stop="drawer = !drawer">
              <span class="ariaonly">Close</span>
              <svg-icon path="mdiClose" />
            </v-btn>
          </div>
        </v-list-item-content>
      </v-list-item>
      <v-spacer />
      <v-list dense nav class="py-0 mt-5">
        <v-list-item to="/scanner">
          <v-list-item-icon>
            <svg-icon path="mdiQrcodeScan" />
          </v-list-item-icon>
          <v-list-item-content> Scan QR Code </v-list-item-content>
        </v-list-item>
        <v-list-item to="/add-order" v-if="isAdmin">
          <v-list-item-icon>
            <svg-icon path="mdiDatabasePlus" />
          </v-list-item-icon>
          <v-list-item-content> Add Order </v-list-item-content>
        </v-list-item>
        <v-list-item to="/record-kit">
          <v-list-item-icon>
            <svg-icon path="mdiContentSave" />
          </v-list-item-icon>
          <v-list-item-content> Record Kit </v-list-item-content>
        </v-list-item>
        <v-list-item to="/artist-log">
          <v-list-item-icon>
            <svg-icon path="mdiEye" />
          </v-list-item-icon>
          <v-list-item-content> View Log </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="deferredPrompt" @click="promptInstall">
          <v-list-item-icon>
            <svg-icon path="mdiOpenInApp" />
          </v-list-item-icon>
          <v-list-item-content> Install App </v-list-item-content>
        </v-list-item>
        <v-list-item @click="logoutArtist">
          <v-list-item-icon>
            <svg-icon path="mdiLogout" />
          </v-list-item-icon>
          <v-list-item-content> Logout </v-list-item-content>
        </v-list-item>
      </v-list>
      <div class="details">
        <p>
          &copy; {{ currentYear }} Spirit Pieces<br />
          Version {{ appVersion }}
        </p>
      </div>
    </v-navigation-drawer>
    <spirit-pieces-logo />
    <router-link to="/record-kit">
      <h2>Spirit Pieces</h2>
    </router-link>
    <v-spacer />
    <v-btn text v-if="loggedIn" @click.stop="drawer = !drawer">
      <span class="ariaonly">Menu</span>
      <svg-icon path="mdiMenu" />
    </v-btn>
  </v-app-bar>
</template>

<script>
import { BeforeInstallPromptEvent } from 'vue-pwa-install';
import { isIOS } from 'mobile-device-detect';
import isAdmin from '../mixins/isAdmin';
import store from '../store';
import { AUTH_LOGOUT } from '../store/actions/shopifyCustomerAuth';
import spiritPiecesLogo from './spiritPiecesLogo.vue';
import svgIcon from './svgIcon.vue';

export default {
  name: 'appHeader',
  data: () => ({
    // eslint-disable-next-line no-bitwise
    deferredPrompt: BeforeInstallPromptEvent | false,
    drawer: false,
  }),
  components: {
    spiritPiecesLogo,
    svgIcon,
  },
  mixins: [isAdmin],
  methods: {
    /**
     * When the Install App button is clicked, this triggers the in-browser installation prompt
     */
    promptInstall() {
      // Show the installation prompt:
      this.deferredPrompt.prompt();
      // Wait for the user to respond to the prompt:
      this.deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the install prompt');
        } else {
          console.log('User dismissed the install prompt');
        }
        this.deferredPrompt = null;
      });
    },

    /**
     * Dispatch the logout method from the VueX Store when Logout is clicked
     */
    logoutArtist() {
      this.$store.dispatch(AUTH_LOGOUT).then(() => {
        setTimeout(() => {
          this.$router.push('/');
        }, 1000);
      });
    },
  },
  created() {
    this.isAdmin();
    /**
     * Hook into the canInstall event to stash the prompt event
     */
    this.$on('canInstall', (event) => {
      // Prevent Chrome 67 and earlier from automatically showing the prompt:
      event.preventDefault();
      // Stash the event so it can be triggered later:
      this.deferredPrompt = event;
    });
  },
  mounted() {
    // Show Install Banner for IOS Devices, which do not support the in-app installation
    if (isIOS) {
      this.$toasted.show(
        '<p>Install the app by using the Share icon below.</p>',
        {
          position: 'bottom-center',
          // Need to use 'custom-class' for the MD icons to work even though it supposedly supports MD natively
          iconPack: 'custom-class',
          icon: {
            name: 'mdi mdi-arrow-down-bold',
          },
          duration: 10000,
          fullWidth: true,
          fitToScreen: true,
          singleton: true,
        }
      );
    }
  },
  computed: {
    loggedIn: () => !!store.getters.isAuthenticated,
    artistName: () => {
      const { artistInfo } = store.getters;
      if (typeof artistInfo.customer !== 'undefined') {
        return artistInfo.customer.displayName;
      }
      return '';
    },
    appVersion: () =>
      process.env.VUE_APP_BUILD_ID
        ? process.env.VUE_APP_BUILD_ID.substring(0, 6)
        : '1.04',
    currentYear: () => {
      const today = new Date();
      return today.getFullYear();
    },
  },
};
</script>

<style scoped lang="scss">
.close__button {
  justify-content: flex-end;
  text-align: right;
}
.has-cursor {
  cursor: pointer;
}
.ariaonly {
  font-size: 0;
}
.details {
  margin-top: 1rem;
  p {
    font-size: 12px;
    text-align: center;
  }
}
a {
  h2 {
    font-family: 'Kreon', sans-serif;
    text-transform: uppercase;
    letter-spacing: 3px;
    color: #fff;
    text-decoration: none;
  }
}
</style>
