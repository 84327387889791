<template>
  <router-link to="/record-kit">
    <v-img
      alt="Spirit Pieces Logo"
      class="shrink mr-4"
      contain
      src="@/assets/android-chrome-192x192.png"
      transition="scale-transition"
      width="40"
    />
  </router-link>
</template>

<script>
export default {
  name: 'spiritPiecesLogo',
};
</script>
