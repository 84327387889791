import store from '../store';

export default {
  methods: {
    /**
     * Determine if logged in user is an admin, based on a tag placed
     * on the customer account in Shopify ("app_admin")
     */
    isAdmin() {
      const { artistInfo } = store.getters;
      if (artistInfo.tags) {
        return artistInfo.tags.indexOf('app_admin') > -1;
      }
      return false;
    },
  },
};
