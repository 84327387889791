<template>
  <svg class="icon" :width="width" viewBox="0 0 24 24">
    <title v-html="title" />
    <path :d="icons[path]" />
  </svg>
</template>

<script>
import svgIcons from '../mixins/svgIcons';

export default {
  name: 'SvgIcon',
  props: {
    path: String,
    width: {
      type: Number,
      default: 20,
      required: false,
    },
    title: {
      type: String,
      default: 'SVG Icon',
      required: false,
    },
  },
  mixins: [svgIcons],
  mounted() {
    setTimeout(this.swapSVGIcons, 250);
  },
};
</script>

<style scoped>
svg.icon {
  fill: currentColor;
}
</style>
