/**
 * Vuetify Framework
 *
 * https://vuetifyjs.com/
 */
import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#204960',
        secondary: '#11538e',
        accent: '#8c9eff',
        error: '#b71c1c',
        success: '#405f33',
        info: '#0a6ebd',
      },
    },
    options: {
      customProperties: true,
    },
  },
});
