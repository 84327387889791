import Vue from 'vue';
import Toasted from 'vue-toasted';
import VueOffline from 'vue-offline';
import App from './App.vue';
import './registerServiceWorker';
import vuetify from './plugins/vuetify';
import router from './router';
import store from './store';

// Import global styles
import './styles/style.scss';

require('./plugins/pwaInstall');

// Development Environment Enhancements
if (process.env.NODE_ENV === 'development') {
  console.log('~~~ Loading Development Tools');

  // eslint-disable-next-line global-require
  const VueAxe = require('vue-axe').default;

  // Load AXE for Accessibility Audits
  Vue.use(VueAxe, {});
}

// Import VueOffline Mixin to display content when offline
Vue.use(VueOffline);

// Import Toasted Library for popup messaging
Vue.use(Toasted);

// Disable production tip
Vue.config.productionTip = false;

// Instantiate Vue Instance
new Vue({
  render: (h) => h(App),
  vuetify,
  router,
  store,
  mounted: () => document.dispatchEvent(new Event('x-app-rendered')),
}).$mount('#app');
