import Lambda from '../../scripts/lambda';
// eslint-disable-next-line import/no-cycle
import store from '..';

import {
  SAVE_ORDER,
  UPDATE_ORDER,
  STORE_ORDER,
  RESET_ORDER,
} from '../actions/order';

const state = {
  shipStationOrder: {},
};

const getters = {
  shipStationOrderData: (state) => state.shipStationOrder,
};

const actions = {
  /**
   * Actions to be taken to save the order, update the tracking database and send
   * the email to the customer
   *
   * @param commit
   * @param [orderData]
   * @return {Promise<unknown>}
   */
  [SAVE_ORDER]: ({ commit }, orderData) => {
    commit(SAVE_ORDER, orderData);
    return new Promise((resolve, reject) => {
      // Add the order to the tracking database
      console.log(store.getters.shipStationOrderData);
      Lambda.addWorkflowItem(
        store.getters.artistInfo.customer.displayName,
        store.getters.shipStationOrderData
      )
        // eslint-disable-next-line consistent-return
        .then((airtableResponse) => {
          // Check for an error response which is prefixed with "ERROR"
          if (airtableResponse.data.indexOf('ERROR') > -1) {
            return reject(airtableResponse.data.toString());
          }
          // Send the email to the customer that the artist has received the vial
          Lambda.sendArtistReceivedVial(orderData)
            .then(() => {
              // Update the tracking database if the email was successfully sent
              Lambda.updateEmailSuccess(airtableResponse.data)
                .then(() => {
                  commit(RESET_ORDER);
                  resolve(
                    `Thank you, order ${orderData.shopifyOrderID} for ${store.getters.artistInfo.customer.displayName} has been entered.`
                  );
                })
                .catch((e) => {
                  reject(new Error(e.toString()));
                });
            })
            .catch((e) => {
              reject(new Error(e.toString()));
            });
        })
        .catch((e) => {
          reject(new Error(e.toString()));
        });
    });
  },
  /**
   * Update an existing order in AirTable
   *
   * @param commit
   * @param orderData
   * @returns {Promise<unknown>}
   */
  [UPDATE_ORDER]: ({ commit }, orderData) => {
    console.log(orderData);
    commit(UPDATE_ORDER, orderData);
    return new Promise((resolve, reject) => {
      // Add the order to the tracking database
      Lambda.updateWorkflowItem(
        store.getters.artistInfo.customer.displayName,
        orderData
      )
        // eslint-disable-next-line consistent-return
        .then((airtableResponse) => {
          // Check for an error response which is prefixed with "ERROR"
          if (airtableResponse.data.indexOf('ERROR') > -1) {
            return reject(airtableResponse.data.toString());
          }
          if (orderData.customerEmail !== 'unknown@customer.com') {
            Lambda.sendArtistReceivedVial(orderData)
              .then(() => {
                // Update the tracking database if the email was successfully sent
                Lambda.updateEmailSuccess(airtableResponse.data)
                  .then(() => {
                    commit(RESET_ORDER);
                    if (
                      orderData.ArtistMessage &&
                      orderData.ArtistMessage.length > 0
                    ) {
                      Lambda.sendArtistMessageToArtist(orderData).then(() => {
                        resolve(
                          `Thank you, order ${orderData.shopifyOrderID} for ${orderData.customerUsername} has been updated.`
                        );
                      });
                    } else {
                      resolve(
                        `Thank you, order ${orderData.shopifyOrderID} for ${orderData.customerUsername} has been updated.`
                      );
                    }
                  })
                  .catch((e) => {
                    reject(new Error(e.toString()));
                  });
              })
              .catch((e) => {
                reject(new Error(e.toString()));
              });
          } else {
            resolve(
              `Thank you, order ${orderData.shopifyOrderID} for ${orderData.customerUsername} has been created.`
            );
          }
        })
        .catch((e) => {
          reject(new Error(e.toString()));
        });
    });
  },
  /**
   * Store the order data without committing to the database
   *
   * @param commit
   * @param [orderData]
   */
  [STORE_ORDER]: ({ commit }, orderData) =>
    new Promise((resolve, reject) => {
      try {
        commit(SAVE_ORDER, orderData);
        resolve();
      } catch (e) {
        reject(new Error(e.toString()));
      }
    }),

  /**
   * Reset the state after a successful operation
   *
   * @param commit
   * @returns {Promise<unknown>}
   */
  [RESET_ORDER]: ({ commit }) =>
    new Promise((resolve) => {
      commit(RESET_ORDER);
      resolve();
    }),
};

const mutations = {
  [SAVE_ORDER]: (state, orderData) => {
    state.shipStationOrder = orderData;
  },
  [UPDATE_ORDER]: (state, orderData) => {
    state.shipStationOrder = orderData;
  },
  [RESET_ORDER]: (state) => {
    state.shipStationOrder = {};
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
