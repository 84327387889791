import Vuex from 'vuex';
import Vue from 'vue';
import createPersistedState from 'vuex-persistedstate';
import shopifyCustomerAuth from './modules/shopifyCustomerAuth';
import artist from './modules/artist';
// eslint-disable-next-line import/no-cycle
import orders from './modules/order';

Vue.use(Vuex);

/**
 * Generate Vuex Store using Modules
 *
 * @type {Store<unknown>}
 */
const store = new Vuex.Store({
  modules: {
    shopifyCustomerAuth,
    artist,
    orders,
  },
  // Use Vuex Persisted State plugin to store VueX data in session storage
  // to persist in case of refresh
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
    }),
  ],
});

export default store;
