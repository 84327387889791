import Vue from 'vue';
import shopifyStorefront from '../../scripts/shopifyStorefront';
import { USER_REQUEST, USER_ERROR, USER_SUCCESS } from '../actions/artist';
import { AUTH_LOGOUT } from '../actions/shopifyCustomerAuth';

/**
 * Artist store module, stores authentication info about the currently logged in artist and
 * retrieves information from Shopify about the artist
 */

const state = {
  status: '',
  isValidArtist: false,
  artistInfo: {},
};

const getters = {
  artistInfo: (state) => state.artistInfo,
  status: (state) => state.status,
  isAuthenticated: (state) => state.isValidArtist,
};

const actions = {
  /**
   * Use the Shopify Storefront API to get information about the artist using the token
   * retrieved during login
   *
   * @param commit
   * @param dispatch
   * @param token
   * @return {Promise<unknown>}
   */
  [USER_REQUEST]: ({ commit, dispatch }, token) =>
    new Promise((resolve, reject) => {
      // Retrieve customer information using the access token
      shopifyStorefront
        .getCustomerInfo(token)
        // eslint-disable-next-line consistent-return
        .then((artistInfo) => {
          // If no data is returned in the response, the token is invalid, so
          // we can assume the login information is incorrect
          if (typeof artistInfo.data === 'undefined') {
            commit(USER_ERROR);
            dispatch(AUTH_LOGOUT);
            reject(new Error('Invalid artist information received'));
          }
          let artistData = artistInfo.data;
          if (typeof artistData.data !== 'undefined') {
            artistData = artistData.data;
          }
          // Artist accounts should be tagged with "artist" as identification
          if (typeof artistData.customer.tags !== 'undefined') {
            const tagIndex = artistData.customer.tags.indexOf('artist');
            if (tagIndex > -1) {
              // Store the artist information returned from the GraphQL call
              commit(USER_SUCCESS, artistData);
              return resolve(artistData);
            }
          }
          // If we got to this point there is something wrong, so return an error
          commit(USER_ERROR);
          dispatch(AUTH_LOGOUT);
          reject(new Error('Error within artist authentication'));
        })
        .catch((e) => {
          commit(USER_ERROR);
          dispatch(AUTH_LOGOUT);
          reject(e);
        });
    }),
};

const mutations = {
  [USER_REQUEST]: (state) => {
    state.status = 'loading';
  },
  [USER_SUCCESS]: (state, artistInfo) => {
    state.status = 'success';
    state.artistInfo = artistInfo;
    state.isValidArtist = true;
    Vue.set(state, 'artistInfo', artistInfo);
  },
  [USER_ERROR]: (state) => {
    state.status = 'error';
  },
  [AUTH_LOGOUT]: (state) => {
    state.profile = {};
    state.status = 'loggedout';
    state.isValidArtist = false;
    localStorage.removeItem('customer-token');
    sessionStorage.clear();
    window.tracker.stop();
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
