<template>
  <v-app>
    <app-header />
    <v-main>
      <transition name="fade">
        <router-view />
      </transition>
    </v-main>
    <app-footer />
    <v-snackbar
      bottom
      center
      app
      :value="updateExists"
      :timeout="10000"
      color="info"
    >
      New Version Available!
      <template v-slot:action="{ attrs }">
        <v-btn text color="white" v-bind="attrs" @click="refreshApp">
          Update App
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import appHeader from './components/appHeader.vue';
import appFooter from './components/appFooter.vue';
import updatePWA from './mixins/updatePWA';

export default {
  name: 'App',
  components: { appHeader, appFooter },
  mixins: [updatePWA],
};
</script>
<style lang="scss">
#app {
  margin-top: 30px;
}
h2 {
  font-family: 'Kreon', sans-serif;
  text-transform: uppercase;
  letter-spacing: 3px;
}
.toasted-container .toasted p {
  font-family: 'Roboto', sans-serif;
}
.v-picker {
  .v-date-picker-title__date {
    font-family: 'Kreon', sans-serif;
    font-size: 1.25em;
  }
}
@media screen and (max-width: 400px) {
  h2 {
    font-size: 16px;
  }
}
</style>
