const axios = require('axios');

/**
 * Methods to interact with the Shopify Storefront API, primarily to validate Customers within the
 * Shopify Store
 */
class ShopifyStorefront {
  /**
   * Set the domain used for GraphQL API calls
   */
  constructor() {
    this.graphQlDomain = `https://${process.env.VUE_APP_SHOPIFY_DOMAIN}/api/2019-07/graphql`;
  }

  /**
   * Use the GraphQL mutation to obtain a customer access token as a means to verify that the
   * artist has logged in and authenticated
   *
   * @param {string} email
   * @param {string} password
   * @return {*|AxiosPromise}
   * @TODO Handle errors
   */
  doAccessTokenRequest(email, password) {
    return axios({
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'X-Shopify-Storefront-Access-Token':
          process.env.VUE_APP_SHOPIFY_STOREFRONT_TOKEN,
      },
      method: 'post',
      url: this.graphQlDomain,
      data: {
        query: `
                    mutation customerAccessTokenCreate($input: CustomerAccessTokenCreateInput!) {
                      customerAccessTokenCreate(input: $input) {
                        customerAccessToken {
                          accessToken
                          expiresAt
                        }
                        customerUserErrors {
                          code
                          field
                          message
                        }
                      }
                    }
                `,
        variables: {
          input: {
            email,
            password,
          },
        },
      },
    });
  }

  /**
   * Retrieve information about the authenticated customer
   *
   * @param {string} accessToken
   * @return {*|AxiosPromise}
   */
  getCustomerInfo(accessToken) {
    return axios({
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'X-Shopify-Storefront-Access-Token':
          process.env.VUE_APP_SHOPIFY_STOREFRONT_TOKEN,
      },
      method: 'post',
      url: this.graphQlDomain,
      data: {
        query: `
                    {
                      customer (customerAccessToken: "${accessToken}"){
                        displayName
                        id
                        email
                        tags
                      }
                    }
                `,
      },
    });
  }
}

const q = new ShopifyStorefront();
export default q;
